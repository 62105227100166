import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  ExpandableSidebar,
  ObjectType,
  useDidMount,
  clsx,
  useMediaBreakpoints,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./pending-upload-media-sub-sidebar.module.css";
import { CategoriesModel } from "src/models";

export type PendingUploadMediaSubSidebarProps = {
  isOpen: boolean;
  setClose: VoidFunction;
  onFinalClosed: VoidFunction;
  options: ObjectType;
};

export const PendingUploadMediaSubSidebar: React.FC<PendingUploadMediaSubSidebarProps> = observer(
  ({ isOpen, setClose, onFinalClosed, options }) => {
    const { mediaStore, localeStore, modalsStore } = useStores();

    const { isMobileMedia } = useMediaBreakpoints();
    const [mediaList, setMediaList] = useState([] as CategoriesModel[]);
    const withThumbnail = mediaList.find((item) => item.item_name === "with_thumbnail");
    const withoutThumbnail = mediaList.find((item) => item.item_name === "without_thumbnail");

    const handleClose = () => {
      setClose();
      modalsStore.open("PendingUploadMediaSidebar", {});
    };

    useDidMount(async () => {
      const res = await mediaStore.getMediaPendingUploadGroupList(
        options?.item_details?.serialNumber,
      );
      if (res?.categories && res?.categories.length > 0) {
        setMediaList(res.categories);
      }
    });

    return (
      <ExpandableSidebar
        title={localeStore
          .t('media.modals["pending-upload-media-sidebar"]["pending-up-from"]')
          .replace(
            localeStore.t('media.modals["pending-upload-media-sidebar"]["unknown-source"]'),
            options?.item_details?.deviceName ||
              localeStore.t('media.modals["pending-upload-media-sidebar"]["unknown-source"]'),
          )}
        icon="cross"
        iconPos={isMobileMedia ? "right" : "outside"}
        sidebarContentClassName={styles.sidebarContent}
        isOpen={isOpen}
        setClose={handleClose}
        onFinalClosed={onFinalClosed}
      >
        <div
          className={clsx(styles.listContainer, globalStyles.addScrollStyles, {
            [styles.mobileMedia]: isMobileMedia,
          })}
        >
          <div>
            <span className={styles.subTitle}>
              {withoutThumbnail?.total_items || 0}{" "}
              {localeStore.t('media.modals["pending-upload-media-sidebar"]["missing-thumbnails"]')}
            </span>
            <div className={styles.listLine}></div>
            <span className={styles.subTitle}>
              {withThumbnail?.total_items || 0}{" "}
              {localeStore.t('media.modals["pending-upload-media-sidebar"]["with-thumbnails"]')}
            </span>
            <div className={styles.listWrapper}>
              {withThumbnail?.filtered_items?.map((item, index) => (
                <div className={styles.imageContainer} key={index}>
                  <img src={item?.file?.small || item?.file?.medium} className={styles.image} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ExpandableSidebar>
    );
  },
);
