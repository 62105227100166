import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Modal,
  Typography,
  Image,
  SvgIcon,
  Button,
  useBoolean,
  useDebounce,
  Info,
  Loader,
} from "@gemlightbox/core-kit";

import { ShareType } from "../share-setting-modal.constants";
import {
  LinkInfo,
  LinkSwitchKeys,
  LinkUpdateReq,
} from "src/api/graphql-api/share-settings/share-settings.interface";
import { PasswordInput } from "../password-input/password-input";
import { useNavigate } from "react-router-dom";
import { useStores } from "src/hooks";
import { copyText } from "../share-setting-modal.util";
import { MuiSwitch } from "../../../mui-styled/switch";
import { ResourceType } from "src/api/graphql-api/share-settings/share-settings.constants";
import { LocaleStore } from "src/store/locale";
import { SettingListType } from "../share-setting-modal.types";
import { getFacebookShareLink, getWhatsappShareLink } from "src/utils";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { getXShareLink } from "../../../../../utils/entities/product/get-x-share-link.utils";

import FacebookImg from "src/external-ts/assets/images/share-settings/facebook.png";
import WathsAppImg from "src/external-ts/assets/images/share-settings/whatsapp.png";
import MailImg from "src/external-ts/assets/images/share-settings/mail.png";
import XImg from "src/external-ts/assets/images/share-settings/x.png";
import { ReactComponent as CopySVG } from "src/external-ts/assets/images/copy-grey.svg";
import { ReactComponent as TipsSvg } from "src/external-ts/assets/images/share-settings/tips.svg";
import { ReactComponent as ShareTipSVG } from "src/external-ts/assets/images/share-settings/share-tip.svg";
import styles from "./share-setting.module.scss";

type ShareSettingProps = {
  shareType: ShareType;
  onLoad: () => Promise<LinkInfo | undefined>;
  onSubmit: (id: number, linkUpdateReq: LinkUpdateReq) => void;
};

export const ShareSetting: React.FC<ShareSettingProps> = observer(
  ({ shareType, onLoad, onSubmit }) => {
    const navigate = useNavigate();
    const { debounce } = useDebounce(500);
    const { modalsStore, notificationStore, localeStore } = useStores();
    const { canPrivateLink } = useLimits();
    const [linkInfo, setLinkInfo] = useState<LinkInfo>({
      id: 0,
      uuid: "",
      resourceId: 0,
      resourceType: _getResourceType(shareType),
      createdAt: "",
      updatedAt: "",
      shareLink: "",
      passwordPlain: "",
      passwordSet: false,
      bannerSet: false,
      expandAttributes: false,
      showBanner: false,
      showCompanyLogo: false,
      showCompanyInfo: false,
      showContactBtn: false,
      showDownloadBtn: false,
      showShoppingCart: false,
    });

    const isLoadingLinkBoolean = useBoolean(false);
    const isCopiedLinkBoolean = useBoolean(false);
    const isLoading = useBoolean(true);
    const [originPassword, setOriginPassword] = useState(linkInfo.passwordPlain || "");
    const [originPasswordSet, setOriginPasswordSet] = useState(linkInfo.passwordSet);
    const [isOldPassword, setIsOldPassword] = useState(originPasswordSet && !originPassword);
    const [linkList, setLinkList] = useState(_getLinkList("", "", ""));
    const passwordInputRef = useRef<HTMLDivElement>(null);
    const menuRefs = useRef<any>({});
    const switchKeys = _getSwitchKeys(shareType);
    const settingList = _getSettingListLocale(localeStore);
    const title = _getShareTitle(shareType, localeStore);
    useEffect(() => {
      onLoad()
        .then((linkInfo) => {
          if (linkInfo) {
            setLinkInfo(linkInfo);
            setOriginPassword(linkInfo.passwordPlain || "");
            setOriginPasswordSet(linkInfo.passwordSet);
            setIsOldPassword(linkInfo.passwordSet && !linkInfo.passwordPlain);
            setLinkList(_getLinkList(linkInfo.shareLink, "title", "description"));
          } else {
            console.error("share data empty", linkInfo);
          }
        })
        .catch((error) => {
          console.error("Failed to share: ", error);
        })
        .finally(() => {
          isLoading.setFalsy();
        });
    }, []);

    const renderWidget = () => {
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load();
      }
    };

    const handleCheckPassword = (
      _isNeedPassword: boolean,
      callback: (_isNeedPassword: boolean) => void,
    ) => {
      if (_isNeedPassword) callback(_isNeedPassword);

      if (originPassword || isOldPassword) {
        notificationStore.open({
          title: _getNotifactionTitle(_isNeedPassword, originPassword, shareType, localeStore),
          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: localeStore.t("common.buttons.cancel"),
          onOk: () => {
            setLinkInfo((preLinkInfo) => ({
              ...preLinkInfo,
              passwordPlain: _isNeedPassword ? originPassword : "",
              passwordSet: _isNeedPassword,
            }));
            setOriginPassword(_isNeedPassword ? originPassword : "");
            setOriginPasswordSet(_isNeedPassword);
            setIsOldPassword(false);
            onSubmit(linkInfo.id, { password: _isNeedPassword ? originPassword : null });
          },
          onCancel: () => {
            callback(_isNeedPassword);
          },
        });
      } else {
        setLinkInfo((preLinkInfo) => ({
          ...preLinkInfo,
          passwordPlain: "",
          passwordSet: _isNeedPassword,
        }));
      }
    };

    const handleSwitchChange = (value: boolean, key: LinkSwitchKeys) => {
      setLinkInfo(() => ({
        ...linkInfo,
        [key]: value,
      }));
      onSubmit(linkInfo.id, { [key]: value });
    };

    const handleCopyLinkSuccess = () => {
      isLoadingLinkBoolean.setTruthy();
      isCopiedLinkBoolean.setTruthy();
      debounce(() => {
        isLoadingLinkBoolean.setFalsy();
      });
      const timer = setTimeout(() => {
        isCopiedLinkBoolean.setFalsy();
        clearTimeout(timer);
      }, 3000);
    };

    const handlePreview = () => {
      try {
        const a = document.createElement("a");
        a.href = linkInfo.shareLink;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error opening link:", error);
      }
    };

    const handlePasswordChange = (value: string) => {
      setLinkInfo(() => ({
        ...linkInfo,
        passwordPlain: value,
      }));
    };

    const handlePasswordBlur = (callback: VoidFunction) => {
      setLinkInfo((preLinkInfo) => ({
        ...preLinkInfo,
        passwordPlain: originPassword,
      }));
      callback();
    };

    const handleSavePassword = (callback: VoidFunction) => {
      notificationStore.open({
        title: _getNotifactionTitle(linkInfo.passwordSet, originPassword, shareType, localeStore),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: localeStore.t("common.buttons.cancel"),
        onOk: () => {
          setOriginPassword(linkInfo.passwordPlain || "");
          setOriginPasswordSet(linkInfo.passwordSet);
          setIsOldPassword(false);
          onSubmit(linkInfo.id, { password: linkInfo.passwordPlain });
          callback();
        },
        onCancel: () => {
          setLinkInfo((preLinkInfo) => ({
            ...preLinkInfo,
            passwordPlain: originPassword,
          }));
          callback();
        },
      });
    };

    const handleLinkToMenu = (event: React.MouseEvent<HTMLElement>, key: LinkSwitchKeys) => {
      const target = event.target as HTMLElement;
      if (target.tagName !== "A") return;
      event.preventDefault();
      const href = menuRefs.current[key].querySelector("a")?.href;
      if (href) {
        const urlObj = new URL(href);
        const path = urlObj.pathname;
        const scrollTo = urlObj.searchParams.get("scrollTo");
        modalsStore.close("ShareSettingModal");
        navigate(path, { state: { scrollTo } });
      } else {
        throw new Error("href is empty");
      }
    };

    const renderSwitch = (key: LinkSwitchKeys) => {
      return (
        <div className={styles.shareSettingItem} key={key}>
          <div className={styles.shareSettingItemLeft}>
            <div className={styles.shareSettingItemInfo}>
              <div className={styles.shareSettingItemTitle}>{settingList[key].title}</div>
              {settingList[key].tips && (
                <Info
                  className={styles.shareSettingItemTip}
                  icon={TipsSvg}
                  size={16}
                  appearance="secondary"
                  withAngle
                >
                  <div style={{ maxWidth: "280px", textAlign: "center" }}>
                    {settingList[key].tips}
                  </div>
                </Info>
              )}
            </div>
            {(linkInfo[key] === null || (!linkInfo.bannerSet && key === "showBanner")) &&
              settingList[key].description && (
                <div
                  ref={(el) => (menuRefs.current[key] = el)}
                  onClick={(event) => {
                    handleLinkToMenu(event, key);
                  }}
                  className={styles.shareSettingItemDescription}
                  dangerouslySetInnerHTML={{ __html: settingList[key].description }}
                />
              )}
          </div>
          <div
            className={[
              styles.shareSettingItemSwitch,
              linkInfo[key] === null ? styles.switchDisabled : "",
            ].join(" ")}
          >
            <MuiSwitch
              disabled={linkInfo[key] === null}
              checked={linkInfo[key] ?? false}
              onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                handleSwitchChange(checked, key);
              }}
            />
          </div>
        </div>
      );
    };

    if (isLoading.value) return <Loader position="absolute" withOverlay />;

    return (
      <div className={styles.shareSettingModal}>
        <div className={styles.shareSettingHeader}>
          <Typography className={styles.title} color="textSecondary">
            {title}
          </Typography>
        </div>
        <div className={styles.shareSettingBody}>
          {ShareType.SHARE_PRODUCTS === shareType && (
            <div className={styles.shareSettingTip}>
              <SvgIcon icon={ShareTipSVG} size={16} />
              {localeStore.t('components.business["share-setting-modal"].tip')}
            </div>
          )}
          <div className={styles.shareSettingContent}>
            {
              <div className={styles.shareSettingLeft}>
                <Typography size="medium" className={styles.shareSettingLeftTitle}>
                  {localeStore.t('components.business["share-setting-modal"].settings.title')}
                </Typography>
                <div className={styles.shareSettingBox}>
                  {[...switchKeys].map((key) => renderSwitch(key))}
                </div>
              </div>
            }
            <div className={styles.shareSettingRight}>
              <div className={styles.shareSettingRightItem}>
                <Typography size="medium" color="textTertiary" className={styles.shareLinkTitle}>
                  {localeStore.t('components.business["share-setting-modal"].link.title')}
                </Typography>
                <div className={styles.link}>
                  <div className={styles.linkInput}>
                    <div className={styles.linkUrl} title={linkInfo.shareLink}>
                      {linkInfo.shareLink}
                    </div>
                    <Button
                      className={styles.copyButton}
                      appearance="primaryGhost"
                      onClick={() => {
                        copyText(linkInfo.shareLink, handleCopyLinkSuccess);
                      }}
                      loading={isLoadingLinkBoolean.value}
                      checked={isCopiedLinkBoolean.value}
                      data-cy="copy-button"
                    >
                      <SvgIcon icon={CopySVG} />
                    </Button>
                  </div>
                  <Button
                    appearance="primary"
                    className={styles.previewButton}
                    onClick={handlePreview}
                  >
                    {localeStore.t('components.business["share-setting-modal"].link.preview')}
                  </Button>
                </div>
                {[ShareType.SHARE_MEDIA, ShareType.SHARE_MEDIAS].includes(shareType) && (
                  <div className={styles.linkDescription}>
                    {localeStore.t('components.business["share-setting-modal"].link.description')}
                  </div>
                )}
              </div>
              {canPrivateLink && (
                <>
                  <div className={styles.shareSettingRightItem}>
                    <PasswordInput
                      originPassword={originPassword}
                      originPasswordSet={originPasswordSet}
                      isOldPassword={isOldPassword}
                      linkInfo={linkInfo}
                      passwordInputRef={passwordInputRef}
                      handleCheckPassword={handleCheckPassword}
                      handlePasswordChange={handlePasswordChange}
                      handlePasswordBlur={handlePasswordBlur}
                      handleSavePassword={handleSavePassword}
                    />
                  </div>
                  <div className={styles.shareSettingRightItem}>
                    <Typography
                      size="medium"
                      color="textTertiary"
                      className={styles.shareSettingPassword}
                    >
                      {localeStore.t('components.business["share-options"].title')}
                    </Typography>
                    <div className={styles.shareSettingIcons}>
                      {linkList.map((linkItem, index) => {
                        return (
                          <a href={linkItem.href} target={linkItem.target || "_blank"} key={index}>
                            <Image src={linkItem.icon} />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export const _getLinkList = (link: string, title: string, description: string) => {
  return [
    {
      href: `mailto:?body=${link}`,
      icon: MailImg,
      target: "_self",
    },
    {
      href: getXShareLink(link),
      icon: XImg,
    },
    {
      href: getFacebookShareLink(link),
      icon: FacebookImg,
    },
    {
      href: getWhatsappShareLink(link),
      icon: WathsAppImg,
    },
  ];
};

const _getSwitchKeys = (shareType: ShareType): Set<LinkSwitchKeys> => {
  switch (shareType) {
    case ShareType.SHARE_PRODUCT:
      return new Set(["showCompanyLogo", "showCompanyInfo", "showContactBtn", "expandAttributes"]);
      break;
    case ShareType.SHARE_PRODUCTS:
    case ShareType.SHARE_CATALOG:
      return new Set([
        "showBanner",
        "showCompanyLogo",
        "showCompanyInfo",
        "showContactBtn",
        "showShoppingCart",
        "expandAttributes",
        "showDownloadBtn",
      ]);
      break;
    case ShareType.SHARE_MEDIA:
    case ShareType.SHARE_MEDIAS:
      return new Set(["showCompanyLogo", "showCompanyInfo", "showContactBtn"]);
      break;
  }
};

const _getResourceType = (shareType: ShareType): ResourceType => {
  if ([ShareType.SHARE_PRODUCT, ShareType.SHARE_PRODUCTS].includes(shareType)) {
    return ResourceType.PRODUCT;
  }
  if ([ShareType.SHARE_MEDIA, ShareType.SHARE_MEDIAS].includes(shareType)) {
    return ResourceType.MEDIA;
  }
  return ResourceType.CATALOG;
};

const _getSettingListLocale = (
  localeStore: LocaleStore,
): { [key in LinkSwitchKeys]: SettingListType } => {
  return {
    showBanner: {
      title: localeStore.t('components.business["share-setting-modal"].settings.banner.title'),
      description: localeStore.t(
        'components.business["share-setting-modal"].settings.banner.description',
      ),
    },
    showCompanyLogo: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["company-logo"].title',
      ),
      description: localeStore.t(
        'components.business["share-setting-modal"].settings["company-logo"].description',
      ),
    },
    showCompanyInfo: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["company-info"].title',
      ),
      description: localeStore.t(
        'components.business["share-setting-modal"].settings["company-info"].description',
      ),
    },
    showContactBtn: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["contact-button"].title',
      ),
      description: localeStore.t(
        'components.business["share-setting-modal"].settings["contact-button"].description',
      ),
      tips: localeStore.t(
        'components.business["share-setting-modal"].settings["contact-button"].tip',
      ),
    },
    showShoppingCart: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["shopping-cart"].title',
      ),
      description: localeStore.t(
        'components.business["share-setting-modal"].settings["shopping-cart"].description',
      ),
      tips: localeStore.t(
        'components.business["share-setting-modal"].settings["shopping-cart"].tip',
      ),
    },
    expandAttributes: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["product-attributes"].title',
      ),
      tips: localeStore.t(
        'components.business["share-setting-modal"].settings["product-attributes"].tip',
      ),
    },
    showDownloadBtn: {
      title: localeStore.t(
        'components.business["share-setting-modal"].settings["download-button"].title',
      ),
      tips: localeStore.t(
        'components.business["share-setting-modal"].settings["download-button"].tip',
      ),
    },
  };
};

const _getNotifactionTitle = (
  isNeedPassword: boolean,
  originPassword: string,
  shareType: ShareType,
  localeStore: LocaleStore,
) => {
  let operation = "add-password";
  if (isNeedPassword && originPassword) {
    operation = "new-password";
  } else if (!isNeedPassword && originPassword) {
    operation = "close-password";
  }
  if (shareType === ShareType.SHARE_PRODUCT) {
    return localeStore.t(
      `components.business["share-setting-modal"].password.notice.product["${operation}"]` as LocaleCodeTypes,
    );
  } else if (shareType === ShareType.SHARE_PRODUCTS) {
    return localeStore.t(
      `components.business["share-setting-modal"].password.notice.products["${operation}"]` as LocaleCodeTypes,
    );
  } else if (shareType === ShareType.SHARE_CATALOG) {
    return localeStore.t(
      `components.business["share-setting-modal"].password.notice.catalog["${operation}"]` as LocaleCodeTypes,
    );
  } else if (shareType === ShareType.SHARE_MEDIA) {
    return localeStore.t(
      `components.business["share-setting-modal"].password.notice.media["${operation}"]` as LocaleCodeTypes,
    );
  } else if (shareType === ShareType.SHARE_MEDIAS) {
    return localeStore.t(
      `components.business["share-setting-modal"].password.notice.medias["${operation}"]` as LocaleCodeTypes,
    );
  }
  return "";
};

const _getShareTitle = (shareType: ShareType, localeStore: LocaleStore) => {
  if (shareType === ShareType.SHARE_CATALOG) {
    return localeStore.t('components.business["share-setting-modal"].title.catalog');
  } else if (shareType === ShareType.SHARE_MEDIA) {
    return localeStore.t('components.business["share-setting-modal"].title.media');
  } else if (shareType === ShareType.SHARE_MEDIAS) {
    return localeStore.t('components.business["share-setting-modal"].title.media');
  } else if (shareType === ShareType.SHARE_PRODUCT) {
    return localeStore.t('components.business["share-setting-modal"].title.product');
  } else if (shareType === ShareType.SHARE_PRODUCTS) {
    return localeStore.t('components.business["share-setting-modal"].title.products');
  }
};
