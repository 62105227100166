import React from "react";
import { observer } from "mobx-react-lite";
import { Button, SvgIcon, Typography, UseBooleanReturnType } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaType } from "src/models";
import { maxImagesPerBatch } from "../../media.constants";

import { ReactComponent as RemoveBackgroundSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";

type RemoveBGButtonProps = {
  visible: UseBooleanReturnType;
  contentSet: React.Dispatch<React.SetStateAction<string>>;
};

export const RemoveBGButton: React.FC<RemoveBGButtonProps> = observer(({ visible, contentSet }) => {
  const { mediaStore, modalsStore, tooltipStore, localeStore, userStore, subscriptionsStore } =
    useStores();

  const isMoreThanMaxImagesPerBatch =
    mediaStore.selectedMediaAmount > maxImagesPerBatch ||
    mediaStore.isPendingUploedMediaSelected ||
    mediaStore.isEclipseModeMediaSelected;

  const hasOnlyImages = mediaStore.selectedMediaList.every(
    (media) => media.type === MediaType.image,
  );

  const isDisabled = !hasOnlyImages || isMoreThanMaxImagesPerBatch;

  const handlePointerEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasOnlyImages) {
      tooltipStore.open(
        <Typography size="small">
          {localeStore.t(
            'media["media-selection-panel"]["remove-bg-button"]["only-images-tooltip"].title',
          )}
        </Typography>,
        {
          position: "bottom",
          appearance: "secondary",
          target: e.currentTarget,
          withAngle: true,
        },
      );

      return;
    }
  };

  const handlePointerLeave = () => {
    if (hasOnlyImages) return;
    tooltipStore.close();
  };

  const handleRemoveBackground = () => {
    if (
      !userStore.isSubscribed &&
      (userStore.userUsage?.countBackgroundRemoval ?? 0) >=
        subscriptionsStore.limits.count_removal_background
    ) {
      contentSet(localeStore.t('common["pro-user"]["remove-bg-upgrade-text"]'));
      visible.setTruthy();
      return;
    }

    modalsStore.open("BatchRemoveBackgroundModal", {
      type: "white",
      medias: mediaStore.selectedMediaList,
    });
    mediaStore.unselectAllMediaList("selected");
  };

  return (
    <Button
      appearance="primaryGhost"
      onClick={handleRemoveBackground}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
      disabled={isDisabled}
      data-cy="remove-bg"
    >
      <SvgIcon icon={RemoveBackgroundSVG} size={20} />
      {localeStore.t('media["media-selection-panel"]["remove-bg-button"].buttons["remove-bg"]')}
    </Button>
  );
});

export default RemoveBGButton;
