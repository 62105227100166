import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  base64ToBlob,
  Button,
  Heading,
  SvgIcon,
  Typography,
  useBoolean,
  useDidMount,
  useRerender,
} from "@gemlightbox/core-kit";

import { MediaType } from "src/models";
import { useStores } from "src/hooks";
import { editMediaStore } from "../edit-media.store";
import { ScaleSelect } from "./scale-select";

import { ReactComponent as BackSVG } from "src/external-ts/assets/images/arrow-left-grey.svg";
import { ReactComponent as NewBadgeSVG } from "src/external-ts/assets/images/badges/new-badge.svg";
import { ReactComponent as UndoSVG } from "src/external-ts/assets/images/undo-arrow-grey.svg";
import { ReactComponent as AiRetouchSVG } from "src/external-ts/assets/images/edit-media/ai-retouch-icon.svg";
import { ReactComponent as RemoveBackgroundSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";
import { ReactComponent as DustRemoveSVG } from "src/external-ts/assets/images/edit-media/dust-remove-icon.svg";
import styles from "./edit-controls-bar.module.css";
import { ProUserUpgradeModal } from "src/external-ts/components";

export type EditControlsBarProps = {
  onGoBack: VoidFunction;
};

export const EditControlsBar: React.FC<EditControlsBarProps> = observer(
  ({ onGoBack }: EditControlsBarProps) => {
    const rerender = useRerender();

    const {
      localeStore,
      notificationStore,
      mediaStore,
      subscriptionsStore,
      userStore,
      tooltipStore,
    } = useStores();
    const {
      isBGRemoved,
      renderer,
      isInMeasurement,
      isInCrop,
      isInResize,
      isInBackground,
      metaData,
    } = editMediaStore;
    const isDisableFeatures = isInCrop || isInMeasurement || isInResize || isInBackground;
    const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
    const proUserUpgradeBool = useBoolean(false);

    const handleConfirmClose = () => {
      notificationStore.open({
        title: localeStore.t('["edit-media"]["cancel-edit"].title'),
        message: localeStore.t('["edit-media"]["cancel-edit"].message'),
        confirmText: localeStore.t("common.buttons.confirm"),
        cancelText: localeStore.t("common.buttons.cancel"),
        dataCy: "close-edit-media-notification",
        onOk: onGoBack,
      });
    };

    const handleRemoveBackground = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countBackgroundRemoval ?? 0) >=
          subscriptionsStore.limits.count_removal_background
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["remove-bg-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }

      editMediaStore.removeBG();
    };

    const handleRetouchAI = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countRetouch ?? 0) >= subscriptionsStore.limits.availableRetouch
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["ai-retouch-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }

      editMediaStore.retouchAI();
    };

    const handleRemoveDust = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countDustRemoval ?? 0) >= subscriptionsStore.limits.count_remove_dust
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["remove-dust-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }

      editMediaStore.removeDust();
    };

    const handleUndo = () => renderer.commands.undo();
    const handleRedo = () => renderer.commands.redo();

    const handleSave = () => {
      const mediaShortModel = editMediaStore.mediaShortModel;
      if (!mediaShortModel) return;

      const base64 = editMediaStore.renderer.export.getCanvasCast().toDataURL();
      const dataUrl = base64.split(",")[1];
      const blob = base64ToBlob(dataUrl, "image/png");
      const formData = new FormData();

      const fileName = mediaShortModel.file.filename.split(".").shift() + ".png";
      formData.append("files", blob, fileName);

      notificationStore.open({
        title: localeStore.t('["edit-media"].save.title'),
        confirmText: localeStore.t('["edit-media"].save["ok-text"]'),
        cancelText: localeStore.t('["edit-media"].save["cancel-text"]'),
        confirmAppearance: "primary",
        onOk: async () => {
          formData.append("type", MediaType.image);
          formData.append("metaData", JSON.stringify({ ...metaData }));
          await mediaStore.updateMedia(mediaShortModel.id, formData);
          onGoBack();
        },
        onCancel: async () => {
          formData.append("types", MediaType.image);
          formData.append("metaData", JSON.stringify({ ...metaData }));
          if (mediaShortModel.product && mediaShortModel.product.mediaCount < 10) {
            formData.append("sku", mediaShortModel.product._id);
          }
          await mediaStore.uploadMedia(formData);
          onGoBack();
        },
      });
    };

    const handleMouseEnter =
      (tooltipText: string, className: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        tooltipStore.open(<Typography size="extraSmall">{tooltipText}</Typography>, {
          target: event.currentTarget,
          appearance: "secondary",
          position: "bottom",
          withAngle: true,
          className: className,
        });
      };

    const handleMouseLeave = () => tooltipStore.close();

    const generateUsageInfo = (limitsCount: number, usedCount: number | undefined | null) => {
      if (!userStore.isSubscribed) {
        return `(${Math.max(0, limitsCount - (usedCount ?? 0))}/${limitsCount})`;
      }
      return "";
    };

    useDidMount(() => {
      return renderer.commands.events.on("history", rerender);
    });

    return (
      <div className={styles.controlsBar}>
        <div className={styles.titleWrapper}>
          <Button
            appearance="secondaryOutlined"
            onClick={handleConfirmClose}
            data-cy="close-button"
          >
            <SvgIcon icon={BackSVG} />
          </Button>
          <Heading tag="h2" color="textSecondary">
            {localeStore.t('["edit-media"].title')}
          </Heading>
        </div>
        <div className={styles.controlsWrapper}>
          {!isDisableFeatures && (
            <Button
              className={styles.removeBGButton}
              appearance="primaryOutlined"
              onClick={handleRemoveBackground}
              data-cy="remove-background-button"
              disabled={
                !!metaData?.isAiBackgroundRemoval ||
                !!metaData?.isAiRetouch ||
                !!metaData?.isEclipseMode
              }
              onMouseEnter={handleMouseEnter(
                `${localeStore.t(
                  '["edit-media"]["edit-controls-bar"]["remove-background"]',
                )}${generateUsageInfo(
                  subscriptionsStore.limits.count_removal_background,
                  userStore.userUsage?.countBackgroundRemoval,
                )}`,
                styles.tooltipBtn,
              )}
              onMouseLeave={handleMouseLeave}
            >
              <SvgIcon className={styles.newBadge} icon={NewBadgeSVG} size={[28, 12]} />
              <SvgIcon icon={RemoveBackgroundSVG} />
              <span>
                {localeStore.t('["edit-media"]["edit-controls-bar"]["remove-background"]')}
                {generateUsageInfo(
                  subscriptionsStore.limits.count_removal_background,
                  userStore.userUsage?.countBackgroundRemoval,
                )}
              </span>
            </Button>
          )}

          {!isDisableFeatures && (
            <Button
              className={styles.retouchAIButton}
              appearance="primaryOutlined"
              onClick={handleRetouchAI}
              data-cy="ai-retouch-button"
              disabled={!!metaData?.isAiRetouch || !!metaData?.isEclipseMode}
              onMouseEnter={handleMouseEnter(
                `${localeStore.t(
                  '["edit-media"]["edit-controls-bar"]["ai-retouch"]',
                )}${generateUsageInfo(
                  subscriptionsStore.limits.availableRetouch,
                  userStore.userUsage?.countRetouch,
                )}`,
                styles.tooltipBtn,
              )}
              onMouseLeave={handleMouseLeave}
            >
              <SvgIcon className={styles.newBadge} icon={NewBadgeSVG} size={[28, 12]} />
              <SvgIcon icon={AiRetouchSVG} />
              <span>
                {localeStore.t('["edit-media"]["edit-controls-bar"]["ai-retouch"]')}
                {generateUsageInfo(
                  subscriptionsStore.limits.availableRetouch,
                  userStore.userUsage?.countRetouch,
                )}
              </span>
            </Button>
          )}

          {!isDisableFeatures && (
            <Button
              className={styles.dustButton}
              appearance="primaryOutlined"
              onClick={handleRemoveDust}
              data-cy="remove-dust-button"
              disabled={
                !!metaData?.isAiRemoveDust || !!metaData?.isAiRetouch || !!metaData?.isEclipseMode
              }
              onMouseEnter={handleMouseEnter(
                `${localeStore.t(
                  '["edit-media"]["edit-controls-bar"]["remove-dust"]',
                )}${generateUsageInfo(
                  subscriptionsStore.limits.count_remove_dust,
                  userStore.userUsage?.countDustRemoval,
                )}`,
                styles.tooltipBtn,
              )}
              onMouseLeave={handleMouseLeave}
            >
              <SvgIcon className={styles.newBadge} icon={NewBadgeSVG} size={[28, 12]} />
              <SvgIcon icon={DustRemoveSVG} />
              <span>
                {localeStore.t('["edit-media"]["edit-controls-bar"]["remove-dust"]')}
                {generateUsageInfo(
                  subscriptionsStore.limits.count_remove_dust,
                  userStore.userUsage?.countDustRemoval,
                )}
              </span>
            </Button>
          )}

          <ScaleSelect className={styles.pctSelect} />
          <Button
            className={styles.undoBtn}
            appearance="primaryOutlined"
            onClick={handleUndo}
            disabled={!renderer.commands.canUndo || isDisableFeatures}
            data-name="undo-button"
            onMouseEnter={handleMouseEnter(
              localeStore.t("common.buttons.undo"),
              styles.tooltipBtn2,
            )}
            onMouseLeave={handleMouseLeave}
          >
            <SvgIcon icon={UndoSVG} />
            <span>{localeStore.t("common.buttons.undo")}</span>
          </Button>
          <Button
            className={styles.redoBtn}
            appearance="primaryOutlined"
            onClick={handleRedo}
            disabled={!renderer.commands.canRedo || isDisableFeatures}
            data-name="redo-button"
            onMouseEnter={handleMouseEnter(
              localeStore.t("common.buttons.redo"),
              styles.tooltipBtn2,
            )}
            onMouseLeave={handleMouseLeave}
          >
            <span>{localeStore.t("common.buttons.redo")}</span>
            <SvgIcon icon={UndoSVG} />
          </Button>

          <Button
            className={styles.saveBtn}
            onClick={handleSave}
            disabled={isDisableFeatures}
            data-cy="save-button"
          >
            {localeStore.t("common.buttons.save")}
          </Button>
        </div>

        <ProUserUpgradeModal
          visible={proUserUpgradeBool}
          onClose={proUserUpgradeBool.setFalsy}
          content={proUserUpgradeContent}
        ></ProUserUpgradeModal>
      </div>
    );
  },
);

export default EditControlsBar;
